
import { Action, Getter } from "vuex-class";
import { Component, Ref, Vue } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit, FormCheckbox, FormYesNo } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import { bus } from "@/main";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormInput,
        FormError,
        FormYesNo,
        FormSubmit,
        FormCheckbox,
    },
})
export default class updateActivityModal extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Action("activity/patch") patchActivity!: (payload: ActivityPatchPayload) => Activity;

    @Ref() form!: FormClass;

    payload: {
        name: string;
        fixed_commission?: null | number;
        relative_commission?: null | number;
        relative_commission_incl_vat: boolean;
        fixed_commission_incl_vat: boolean;
        vat_included?: boolean;
    } = {
        name: "",
        fixed_commission: null,
        relative_commission: 3,
        relative_commission_incl_vat: false,
        fixed_commission_incl_vat: false,
    };

    show = false;

    errorResponse: ErrorResponse = {
        status: 0,
        errors: [],
    };

    mounted() {
        bus.$off("show-update-activity");
        bus.$on("show-update-activity", () => {
            this.payload = {
                name: this.activity.name,
                fixed_commission: this.activity.fixed_commission,
                relative_commission: this.activity.relative_commission,
                relative_commission_incl_vat: this.activity.vat_included ?? false,
                fixed_commission_incl_vat: this.activity.vat_included ?? false,
            };
            this.show = true;
        });
    }

    get isRelative() {
        return this.payload.fixed_commission === null && this.payload.relative_commission !== null;
    }

    get isFixed() {
        return this.payload.relative_commission === null && this.payload.fixed_commission !== null;
    }

    async submit(form: FormClass) {
        try {
            const payload = { id: this.activity.id, name: this.payload.name, fixed_commission: this.payload.fixed_commission, relative_commission: this.payload.relative_commission, vat_included: false };
            if (this.isFixed) {
                payload.vat_included = this.payload.fixed_commission_incl_vat;
            } else if (this.isRelative) {
                payload.vat_included = this.payload.relative_commission_incl_vat;
            }
            await this.patchActivity(payload);

            this.$toast.open({ message: this.$t("views.dossier.update_success") as string, type: "success", position: 'bottom-left' });

            this.handleClose();
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleRelativeCommissionChanged() {
        this.payload.fixed_commission = null;
    }

    handleFixedCommissionChanged() {
        this.payload.relative_commission = null;
    }

    handleClose() {
        this.errorResponse = {
            status: 0,
            errors: [],
        };

        this.show = false;
    }
}
